<template>
    <div class="register-validate-success">
        <div class="status">
            <img
                class="status-img"
                src="../../assets/register/success.png"
                alt=""
            >
            <div class="text">
                <div style="color:#61C88A;">
                    {{ $t('Register.ValidateSuccess.text') }}
                </div>
            </div>
        </div>
        <button
            class="submit-btn"
            @click="backToHome"
        >
            {{ $t('Register.ValidateSuccess.button') }}
        </button>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        backToHome() {
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/register.scss";
.register-validate-success{

}

</style>
